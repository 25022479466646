import React, {useEffect, useState} from "react";
import {UploadRequest} from "../../types/uploadRequest";
import {UploadRequestService} from "../../services/uploadRequestService";
import {useNavigate, useParams} from "react-router-dom";
import XIcon from "../../assets/images/icons/XIcon";
import FileInput from "../../components/inputs/FileInput";
import FileDetailBox from "../../components/FileDetailBox";
import DescriptionInput from "../../components/inputs/DescriptionInput";
import DateInput from "../../components/inputs/DateInput";
import SubmitButton from "../../components/SubmitButton";
import {showToast} from "../../components/ToastContainer";

const UploadRequestPage: React.FC = () => {
  const navigate = useNavigate();
  const [uploadRequest, setUploadRequest] = useState<UploadRequest>();
  const params = useParams();
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFileChange = (files: FileList | null) => {
    if (files && files.length > 0) {
      const attachment = files[0];
      setUploadedFile(attachment);
      setIsLoadingFile(true);
    }
  };
  useEffect(() => {
    UploadRequestService.show(params?.token!).then(response => {
      setUploadRequest(response);
    });
  }, []);

  useEffect(() => {
    if (!isLoadingFile) return;

    const loadingDuration = 2000;
    const timer = setTimeout(() => {
      setIsLoadingFile(false);
      setIsSaveDisabled(false);
    }, loadingDuration);

    return () => clearTimeout(timer);
  }, [isLoadingFile]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (uploadedFile) {
      setIsSubmitting(true);
      const formData = {
        attachment: uploadedFile,
        token: params?.token,
      }
      await UploadRequestService.upload(formData).then(() => {
        setIsSubmitting(false);
        showToast("File uploaded successfully", "success");
      });
    }
  };

  const handleDelete = () => {
    setUploadedFile(null);
    setIsSaveDisabled(true);
  };
  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 flex flex-col">
        <div className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
          <div className="flex flex-col justify-start items-start gap-1">
            <div className="text-lg font-semibold tracking-normal text-cyan-800">
              {`Upload ${uploadRequest?.name}`}
            </div>

            <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
              {`Use the form below to upload your ${uploadRequest?.name} and complete this requirement.`}
            </div>
          </div>
        </div>
        <div className="h-full px-6 pb-6 ">
          <div className="mt-5">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-6 items-center mt-7">
                <div className="w-full">
                  <FileInput
                    onFileChange={handleFileChange}
                    label="Drop your files here or"
                    labelBlue="browse"
                    subtitle="Maximum size: 50MB"
                    disabled={isLoadingFile}
                  />
                </div>
                {uploadedFile && (
                  <div className="w-full">
                    <FileDetailBox
                      uploadedFile={uploadedFile}
                      handleDelete={handleDelete}
                      isLoading={isLoadingFile}
                      loadingTime={2000}
                    />
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
        <div className="border-t border-slate-200 w-full">
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <SubmitButton
              onClick={handleSubmit}
              label={`${isSubmitting ? "Uploading" : "Upload"}`}
              disabled={isSaveDisabled || isSubmitting}
            />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default UploadRequestPage;
