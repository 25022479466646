import React, {useContext, useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import {DocumentTemplateThunks} from 'features/documentTemplate/documentTemplateThunks';
import TextInput from 'components/inputs/TextInput';
import CustomSelect from 'components/inputs/CustomSelect';
import SubmitButton from 'components/SubmitButton';
import PlusIcon from "assets/images/icons/PlusIcon";
import {DocumentTemplate} from "types/documentTemplate";
import {TitleContext} from "components/TitleContext";
import {DocumentTemplateRow} from "types/documentTemplateRow";
import {
    AddressInputElementClass,
    CurrencyInputElementClass,
    DateInputElementClass,
    DateTimeInputElementClass,
    DocumentElementClass,
    DocumentElementType,
    DocumentTemplateElement,
    DropdownElementClass,
    EmailInputElementClass,
    MultiLineTextInputElementClass,
    PhoneNumberInputElementClass,
    RatingInputElementClass,
    SignatureInputElementClass,
    SsnInputElementClass, TableInputElementClass,
    TextBlockElementClass,
    TextInputElementClass,
    TimeInputElementClass,
    UrlInputElementClass,
    YesNoInputElementClass
} from "types/documentTemplateElement";
import {DocumentTemplateColumn} from "types/documentTemplateColumn";
import LogoNewHope from "assets/images/LogoNewHope.png";
import RenderElement from "../../components/documentTemplate/RenderElement";
import RenderElementProperties from "../../components/documentTemplate/RenderElementProperties";
import TrashIcon from "../../assets/images/icons/TrashIcon";
import ArrowTopIcon from "../../assets/images/icons/ArrowTopIcon";
import ArrowBottomIcon from "../../assets/images/icons/ArrowBottomIcon";
import ArrowRightIcon from "../../assets/images/icons/ArrowRightIcon";
import ArrowLeftIcon from "../../assets/images/icons/ArrowLeftIcon";
import RenderElementView from "../../components/documentTemplate/RenderElementView";

const DocumentTemplateFormPage: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const tabs = ['Builder', 'Preview'];
    const [activeTab, setActiveTab] = React.useState('Builder');
    const documentTemplate = useSelector((state: RootState) => state.documentTemplate.documentTemplate);
    const [updatedDocumentTemplate, setUpdatedDocumentTemplate] = React.useState({...documentTemplate});
    const [addRowIsOpen, setAddRowIsOpen] = React.useState(false);
    const [menuPosition, setMenuPosition] = React.useState({top: 0, left: 0,});
    const addRowButtonRef = React.useRef<HTMLButtonElement>(null);
    const addRowDropdownRef = React.useRef<HTMLDivElement>(null);
    const {setTitle} = useContext(TitleContext);
    const selectedElementRef = useRef<HTMLDivElement>(null);
    const propertiesElementRef = useRef<HTMLDivElement>(null);
    const [selectedElement, setSelectedElement] = React.useState<{
        element: DocumentTemplateElement;
        rowId: string;
        colId: string;
    } | null>(null);


    const [openDropdown, setOpenDropdown] = React.useState<{ rowId: string; colId: string } | null>(null);

    const columnDropdownRef = useRef<HTMLDivElement>(null);

    const inputOptions: any =
        {
            'default': [
                {name: 'Text Block', value: 'TextBlock'},
            ],
            'advanced': [
                {name: 'Phone Number', value: 'PhoneNumberInput', category: 'advanced'},
                {name: 'Date', value: 'DateInput', category: 'advanced'},
                {name: 'Social security number', value: 'SsnInput', category: 'advanced'},
                {name: 'Email', value: 'EmailInput', category: 'advanced'},
                {name: 'Address', value: 'AddressInput', category: 'advanced'},
                {name: 'Currency', value: 'CurrencyInput', category: 'advanced'},
                {name: 'Date & Time', value: 'DateTimeInput', category: 'advanced'},
                {name: 'Time', value: 'TimeInput', category: 'advanced'},
                {name: 'Rating', value: 'RatingInput', category: 'advanced'},
                {name: 'Signature', value: 'SignatureInput', category: 'advanced'},
                {name: 'Website (URL)', value: 'UrlInput', category: 'advanced'},

            ],
            'basic': [
                {name: 'Single-line text', value: 'TextInput', category: 'basic'},
                {name: 'Multi-line text', value: 'MultiLineTextInput', category: 'basic'},
                {name: 'Yes/No', value: 'YesNoInput', category: 'basic'},
                {name: 'Dropdown', value: 'Dropdown'},
                {name: 'Table', value: 'TableInput'},
            ]
        };

    useEffect(() => {
        setTitle(
            <div>
                <div className="text-slate-600 text-lg font-medium font-['Inter']">
                    Document Builder
                </div>
                <div className="text-slate-400 text-sm font-light font-['Inter'] leading-normal">
                    Manage custom document templates
                </div>
            </div>
        );
    }, [setTitle]);

    useEffect(() => {
        if (id) {
            dispatch(DocumentTemplateThunks.show(id));
        }
    }, [id, dispatch]);

    useEffect(() => {
        setUpdatedDocumentTemplate({...documentTemplate});
    }, [documentTemplate]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const {id, value} = e.target;
        setUpdatedDocumentTemplate({...updatedDocumentTemplate, [id]: value});
    };

    const toggleDropdown = (dropdown: 'addRow', event: React.MouseEvent<HTMLButtonElement>) => {
        if (dropdown === 'addRow') {
            setAddRowIsOpen(!addRowIsOpen);
        }

        const buttonRect = event.currentTarget.getBoundingClientRect();
        setMenuPosition({
            top: buttonRect.top + buttonRect.height,
            left: buttonRect.left - buttonRect.width
        });
    };

    const handlePublish = () => {
        console.log(updatedDocumentTemplate);
        if (updatedDocumentTemplate.id) {
            dispatch(DocumentTemplateThunks.update(new DocumentTemplate(updatedDocumentTemplate)));
        } else {
            dispatch(DocumentTemplateThunks.create(new DocumentTemplate(updatedDocumentTemplate)));
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            addRowDropdownRef.current &&
            !addRowDropdownRef.current.contains(event.target as Node) &&
            addRowButtonRef.current &&
            !addRowButtonRef.current.contains(event.target as Node)
        ) {
            setAddRowIsOpen(false);
        }
        if (
            (columnDropdownRef.current &&
                !columnDropdownRef.current.contains(event.target as Node))
            || !columnDropdownRef.current
        ) {
            setOpenDropdown(null);
        }
        if (
            selectedElementRef.current &&
            !selectedElementRef.current.contains(event.target as Node) &&
            propertiesElementRef.current &&
            !propertiesElementRef.current.contains(event.target as Node) &&
            // validates if target has a class name
            event.target && !(event.target as Element).classList.contains('border-dashed')
        ) {
            setSelectedElement(null);
        }

    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleAddRow = (columnsCount: number) => {
        if (!updatedDocumentTemplate.rows) {
            setUpdatedDocumentTemplate((prevTemplate: any) => ({
                ...prevTemplate,
                rows: [],
            }));
        }
        const newRow = {
            id: `row-${Date.now()}`,
            columns: Array.from({length: columnsCount}).map((_, index) => ({
                id: `col-${Date.now()}-${index}`,
                width: 1 / columnsCount,
                element: null,
            })),
        };

        setUpdatedDocumentTemplate((prevTemplate: any) => ({
            ...prevTemplate,
            rows: [...prevTemplate.rows, newRow],
        }));
        setAddRowIsOpen(false);
    };

    const handleDeleteRow = (rowIndex: number) => {
        setUpdatedDocumentTemplate((prevTemplate: any) => {
            const newRows = [...prevTemplate.rows];
            if (/^\d+$/.test(newRows[rowIndex].id)) {
                newRows[rowIndex] = {...newRows[rowIndex], _destroy: true};
            } else {
                newRows.splice(rowIndex, 1);
            }
            return {...prevTemplate, rows: newRows};
        });
    };
    const handleUpRow = (rowIndex: number) => {
        if (rowIndex === 0) return;
        setUpdatedDocumentTemplate((prevTemplate: any) => {
            const newRows = prevTemplate.rows.map((row: any) => ({...row}));

            [newRows[rowIndex], newRows[rowIndex - 1]] = [
                newRows[rowIndex - 1],
                newRows[rowIndex],
            ];

            newRows[rowIndex].order = rowIndex;
            newRows[rowIndex - 1].order = rowIndex - 1;
            return {...prevTemplate, rows: newRows};
        });
    };
    const handleDownRow = (rowIndex: number) => {
        setUpdatedDocumentTemplate((prevTemplate: any) => {
            if (rowIndex >= prevTemplate.rows.length - 1) return prevTemplate;
            const newRows = prevTemplate.rows.map((row: any) => ({...row}));

            [newRows[rowIndex], newRows[rowIndex + 1]] = [
                newRows[rowIndex + 1],
                newRows[rowIndex],
            ];

            newRows[rowIndex].order = rowIndex;
            newRows[rowIndex + 1].order = rowIndex + 1;
            return {...prevTemplate, rows: newRows};
        });
    };
    const handleDeleteItem = (rowIndex: number, colIndex: number) => {
        setUpdatedDocumentTemplate((prevTemplate: any) => {
            const newRows = [...prevTemplate.rows];
            const rowToUpdate = {...newRows[rowIndex]};
            const newColumns = [...rowToUpdate.columns];
            const colToUpdate = {...newColumns[colIndex]};

            if (colToUpdate.element && /^\d+$/.test(colToUpdate.element.id)) {
                colToUpdate.element = {...colToUpdate.element, _destroy: true};
            } else {
                colToUpdate.element = null;
            }
            newColumns[colIndex] = colToUpdate;
            rowToUpdate.columns = newColumns;
            newRows[rowIndex] = rowToUpdate;
            return {...prevTemplate, rows: newRows};
        });
    };


    const handleLeftItem = (rowIndex: number, colIndex: number) => {
        setUpdatedDocumentTemplate((prevTemplate: any) => {
            const newRows = [...prevTemplate.rows];

            const rowToUpdate = {...newRows[rowIndex]};

            const newColumns = rowToUpdate.columns.map((col: any) => ({...col}));

            [newColumns[colIndex - 1], newColumns[colIndex]] = [
                newColumns[colIndex],
                newColumns[colIndex - 1]
            ];

            newColumns[colIndex - 1].order = colIndex - 1;
            newColumns[colIndex].order = colIndex;

            rowToUpdate.columns = newColumns;
            newRows[rowIndex] = rowToUpdate;
            return {...prevTemplate, rows: newRows};
        });
    };
    const handleRightItem = (rowIndex: number, colIndex: number) => {
        setUpdatedDocumentTemplate((prevTemplate: any) => {
            const newRows = [...prevTemplate.rows];

            const rowToUpdate = {...newRows[rowIndex]};

            const newColumns = rowToUpdate.columns.map((col: any) => ({...col}));

            [newColumns[colIndex], newColumns[colIndex + 1]] = [
                newColumns[colIndex + 1],
                newColumns[colIndex]
            ];

            newColumns[colIndex].order = colIndex;
            newColumns[colIndex + 1].order = colIndex + 1;

            rowToUpdate.columns = newColumns;
            newRows[rowIndex] = rowToUpdate;
            return {...prevTemplate, rows: newRows};
        });
    };

    const handleSelectElement = (
        rowId: string,
        colId: string,
        elementType: string
    ) => {
        elementType = elementType.replace(' ', '');
        let newElement: DocumentElementClass;

        switch (elementType) {
            case 'TextBlock':
                newElement = new TextBlockElementClass({
                    id: `element-${Date.now()}`,
                    elementType: 'TextBlock',
                    properties: {
                        content: '',
                    },
                });
                break;
            case 'TextInput':
                newElement = new TextInputElementClass({
                    id: `element-${Date.now()}`,
                    elementType: 'TextInput',
                    properties: {
                        label: 'Label',
                        required: false,
                        showLabel: false,
                        placeholder: '',
                        adminOnly: false,
                    },
                });
                break;
            case 'MultiLineTextInput':
                newElement = new MultiLineTextInputElementClass({
                    id: `element-${Date.now()}`,
                    elementType: 'MultiLineTextInput',
                    properties: {
                        label: 'Label',
                        required: false,
                        showLabel: false,
                        placeholder: '',
                        adminOnly: false,
                    },
                });
                break;
            case 'PhoneNumberInput':
                newElement = new PhoneNumberInputElementClass({
                    id: `element-${Date.now()}`,
                    elementType: 'PhoneNumberInput',
                    properties: {
                        label: 'Label',
                        required: false,
                        showLabel: false,
                        placeholder: '',
                        phoneNumber: '',
                        adminOnly: false,
                    },
                });
                break;
            case 'YesNoInput':
                newElement = new YesNoInputElementClass({
                    id: `element-${Date.now()}`,
                    elementType: 'YesNoInput',
                    properties: {
                        label: 'Label',
                        required: false,
                        showLabel: false,
                        adminOnly: false,
                    },
                });
                break;
            case 'DateInput':
                newElement = new DateInputElementClass({
                    id: `element-${Date.now()}`,
                    elementType: 'DateInput',
                    properties: {
                        label: 'Label',
                        required: false,
                        showLabel: false,
                        placeholder: 'Placeholder',
                        allowPastDates: false,
                        adminOnly: false,
                    },
                });
                break;
            case 'Dropdown':
                newElement = new DropdownElementClass({
                    id: `element-${Date.now()}`,
                    elementType: 'Dropdown',
                    properties: {
                        label: 'Label',
                        isMulti: false,
                        required: false,
                        options: [],
                        showLabel: false,
                        adminOnly: false,
                    },
                });
                break;
            case 'SsnInput':
                newElement = new SsnInputElementClass({
                    id: `element-${Date.now()}`,
                    elementType: 'SsnInput',
                    properties: {
                        label: 'Label',
                        required: false,
                        showLabel: false,
                        adminOnly: false,
                    },
                });
                break;
            case 'EmailInput':
                newElement = new EmailInputElementClass({
                    id: `element-${Date.now()}`,
                    elementType: 'EmailInput',
                    properties: {
                        label: 'Label',
                        required: false,
                        showLabel: false,
                        placeholder: '',
                        adminOnly: false,
                    },
                });
                break;
            case 'AddressInput':
                newElement = new AddressInputElementClass({
                    id: `element-${Date.now()}`,
                    elementType: 'AddressInput',
                    properties: {
                        label: 'Label',
                        required: false,
                        showLabel: false,
                        placeholder: '',
                        adminOnly: false,
                    },
                });
                break;
            case 'CurrencyInput':
                newElement = new CurrencyInputElementClass({
                    id: `element-${Date.now()}`,
                    elementType: 'CurrencyInput',
                    properties: {
                        label: 'Label',
                        required: false,
                        showLabel: false,
                        placeholder: '',
                        currencySymbol: '$',
                        adminOnly: false,
                    },
                });
                break;
            case 'DateTimeInput':
                newElement = new DateTimeInputElementClass({
                    id: `element-${Date.now()}`,
                    elementType: 'DateTimeInput',
                    properties: {
                        label: 'Label',
                        required: false,
                        showLabel: false,
                        placeholder: 'Placeholder',
                        allowPastDates: false,
                        adminOnly: false,
                    },
                });
                break;
            case 'TimeInput':
                newElement = new TimeInputElementClass({
                    id: `element-${Date.now()}`,
                    elementType: 'TimeInput',
                    properties: {
                        label: 'Label',
                        required: false,
                        showLabel: false,
                        placeholder: '',
                        adminOnly: false,
                    },
                });
                break;
            case 'RatingInput':
                newElement = new RatingInputElementClass({
                    id: `element-${Date.now()}`,
                    elementType: 'RatingInput',
                    properties: {
                        label: 'Label',
                        required: false,
                        showLabel: false,
                        maxRating: 5,
                        initialRating: 0,
                        adminOnly: false,

                    },
                });
                break;
            case 'SignatureInput':
                newElement = new SignatureInputElementClass({
                    id: `element-${Date.now()}`,
                    elementType: 'SignatureInput',
                    properties: {
                        label: 'Signature',
                        required: false,
                        showLabel: false,
                        adminOnly: false,
                    },
                });
                break;
            case 'UrlInput':
                newElement = new UrlInputElementClass({
                    id: `element-${Date.now()}`,
                    elementType: 'UrlInput',
                    properties: {
                        label: 'Website',
                        required: false,
                        showLabel: false,
                        placeholder: '',
                        adminOnly: false,
                    },
                });
                break;
            case 'TableInput':
                newElement = new TableInputElementClass({
                    id: `element-${Date.now()}`,
                    elementType: 'TableInput',
                    properties: {
                        label: 'Table',
                        rows: 2,
                        columns: 2,
                        showLabel: false,
                        data: [['', ''], ['', '']],
                        adminOnly: false,
                    },
                });
                break;
            default:
                return;
        }

        setUpdatedDocumentTemplate((prevTemplate: any) => {
            const updatedRows = prevTemplate.rows.map((row: { id: string; columns: { id: string; }[]; }) => {
                if (row.id !== rowId) return row;
                return {
                    ...row,
                    columns: row.columns.map((col: { id: string; }) => {
                        if (col.id !== colId) return col;
                        return {
                            ...col,
                            element: newElement,
                        };
                    }),
                };
            });
            return {...prevTemplate, rows: updatedRows};
        });
        setOpenDropdown(null);
        setSelectedElement({
            element: newElement,
            rowId,
            colId,
        });
    };

    return (
      <div className="flex flex-col min-h-[94vh]">
        <div className="mb-8 text-sm font-medium text-center text-slate-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px">
            {tabs.map(tab => (
              <li
                key={tab}
                className={`me-2 cursor-pointer inline-block p-2 border-b-2 text-slate-400 ${
                  activeTab === tab
                    ? "border-cyan-400 text-slate-600 dark:text-gray-200 dark:border-cyan-400"
                    : "border-transparent"
                } rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300`}
                onClick={() => setActiveTab(tab)}
                aria-current={activeTab === tab ? "page" : undefined}>
                {tab}
              </li>
            ))}
          </ul>
        </div>
        {activeTab === "Builder" && (
          <div>
            <div className="relative overflow-hidden">
              <div className="relative">
                <div className="p-5 space-x-4 flex bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
                  <div className="flex-grow">
                    <TextInput
                      id="name"
                      type="text"
                      label="Document Name"
                      value={updatedDocumentTemplate.name || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="w-40">
                    <div className="flex flex-col gap-[0.625rem] w-full">
                      <label
                        htmlFor="enableHeader"
                        className="w-full block text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                        Enable header
                      </label>
                      <CustomSelect
                        menuPortalTarget={document.body}
                        options={[
                          {value: true, label: "Yes"},
                          {value: false, label: "No"},
                        ]}
                        value={updatedDocumentTemplate.enableHeader}
                        onChange={selectedOption => {
                          setUpdatedDocumentTemplate((prevData: any) => ({
                            ...prevData,
                            enableHeader: selectedOption.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-40">
                    <div className="flex flex-col gap-[0.625rem] w-full">
                      <label
                        htmlFor="requiresReview"
                        className="w-full block text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                        Requires review
                      </label>
                      <CustomSelect
                        menuPortalTarget={document.body}
                        options={[
                          {value: true, label: "Yes"},
                          {value: false, label: "No"},
                        ]}
                        value={updatedDocumentTemplate.requiresReview}
                        onChange={selectedOption => {
                          setUpdatedDocumentTemplate((prevData: any) => ({
                            ...prevData,
                            requiresReview: selectedOption.value,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-40 mt-1.5">
                    <SubmitButton
                      onClick={handlePublish}
                      label="Publish"
                      disabled={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-1 pt-8 overflow-hidden">
              <div className="w-9/12 min-h-[70vh] mr-4 p-5 bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700 overflow-auto">
                <>
                  {updatedDocumentTemplate.enableHeader && (
                    <div className="flex mb-4">
                      <div className={`flex-1 px-2 relative`}>
                        <div className="w-full h-full min-h-20 border-2 border-dashed border-gray-300 flex items-center justify-start">
                          <div className="text-slate-600 text-lg font-medium font-['Inter']">
                            <img
                              className="pl-3 w-24 h-auto"
                              src={LogoNewHope}
                              alt="Logo"
                            />
                          </div>
                          <div className="text-slate-400 text-md font-light font-['Inter'] leading-normal ml-1 mr-1">
                            |
                          </div>
                          <div className="text-slate-400 text-md font-light font-['Inter'] leading-normal">
                            {updatedDocumentTemplate.name}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {updatedDocumentTemplate.rows?.map(
                    (row: DocumentTemplateRow, rowIndex: number) => {
                      if (row._destroy) {
                        return null;
                      }
                      return (
                        <div key={row.id} className="flex mb-4">
                          <div className="grid grid-rows-3 mt-2 justify-items-center h-20">
                            <div>
                              <button
                                className="text-slate-400 hover:text-slate-800 mb-2 items-center"
                                onClick={() => handleDeleteRow(rowIndex)}>
                                <TrashIcon />
                              </button>
                            </div>
                            {rowIndex > 0 && (
                              <div>
                                <button
                                  className="text-slate-400 hover:text-slate-800 justify-self-center items-center"
                                  onClick={() => handleUpRow(rowIndex)}>
                                  <ArrowTopIcon />
                                </button>
                              </div>
                            )}
                            {rowIndex <
                              updatedDocumentTemplate.rows.length - 1 && (
                              <div>
                                <button
                                  className="text-slate-400 hover:text-slate-800 justify-self-center items-center "
                                  onClick={() => handleDownRow(rowIndex)}>
                                  <ArrowBottomIcon />
                                </button>
                              </div>
                            )}
                          </div>
                          {row.columns.map(
                            (col: DocumentTemplateColumn, colIndex: number) => {
                              const isSelected =
                                selectedElement &&
                                selectedElement.rowId === row.id &&
                                selectedElement.colId === col.id;
                              return (
                                <div
                                  key={col.id}
                                  className={`flex-1 px-2 relative`}>
                                  {col.element &&
                                  col.element._destroy !== true ? (
                                    <div
                                      ref={
                                        selectedElement &&
                                        selectedElement.rowId === row.id &&
                                        selectedElement.colId === col.id
                                          ? selectedElementRef
                                          : null
                                      }
                                      className={`w-full h-full min-h-20 border-2 border-dashed ${
                                        selectedElement &&
                                        selectedElement.rowId === row.id &&
                                        selectedElement.colId === col.id
                                          ? "border-slate-500"
                                          : "border-slate-200"
                                      } flex items-center justify-center`}
                                      onClick={() => {
                                        if (col.element) {
                                          setSelectedElement({
                                            element: col.element,
                                            rowId: row.id,
                                            colId: col.id,
                                          });
                                        }
                                      }}>
                                      {isSelected && (
                                        <div className="absolute top-0 left-4">
                                          <div className="grid grid-cols-3 mt-2 justify-items-center">
                                            <div>
                                              <button
                                                className="text-slate-400 hover:text-slate-800 mx-1 items-center"
                                                onClick={() =>
                                                  handleDeleteItem(
                                                    rowIndex,
                                                    colIndex,
                                                  )
                                                }>
                                                <TrashIcon />
                                              </button>
                                            </div>
                                            {colIndex > 0 && (
                                              <div>
                                                <button
                                                  className="text-slate-400 hover:text-slate-800 mx-1 justify-self-center items-center"
                                                  onClick={() =>
                                                    handleLeftItem(
                                                      rowIndex,
                                                      colIndex,
                                                    )
                                                  }>
                                                  <ArrowLeftIcon />
                                                </button>
                                              </div>
                                            )}
                                            {colIndex <
                                              row.columns.length - 1 && (
                                              <div>
                                                <button
                                                  className="text-slate-400 hover:text-slate-800 justify-self-center items-center "
                                                  onClick={() =>
                                                    handleRightItem(
                                                      rowIndex,
                                                      colIndex,
                                                    )
                                                  }>
                                                  <ArrowRightIcon />
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                      <RenderElement
                                        element={col.element}
                                        updateElement={updatedElement => {
                                          setUpdatedDocumentTemplate(
                                            (prevTemplate: any) => {
                                              const updatedRows =
                                                prevTemplate.rows.map(
                                                  (
                                                    rowItem: DocumentTemplateRow,
                                                  ) => {
                                                    if (rowItem.id !== row.id)
                                                      return rowItem;
                                                    return {
                                                      ...rowItem,
                                                      columns:
                                                        rowItem.columns.map(
                                                          (
                                                            column: DocumentTemplateColumn,
                                                          ) => {
                                                            if (
                                                              column.id !==
                                                              col.id
                                                            )
                                                              return column;
                                                            return {
                                                              ...column,
                                                              element:
                                                                updatedElement,
                                                            };
                                                          },
                                                        ),
                                                    };
                                                  },
                                                );
                                              return {
                                                ...prevTemplate,
                                                rows: updatedRows,
                                              };
                                            },
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <button
                                      className="w-full h-full min-h-20 border-2 border-dashed border-gray-300 flex items-center justify-center"
                                      onClick={() => {
                                        setOpenDropdown({
                                          rowId: row.id,
                                          colId: col.id,
                                        });
                                      }}>
                                      <PlusIcon />
                                    </button>
                                  )}

                                  {openDropdown &&
                                    openDropdown.rowId === row.id &&
                                    openDropdown.colId === col.id && (
                                      <div
                                        ref={columnDropdownRef}
                                        className="absolute mt-2 pb-3 w-60 bg-white rounded-xl shadow-lg z-10 dark:bg-neutral-900">
                                        <div className="p-1">
                                          {/* Basic Input Elements Section */}
                                          {inputOptions["default"].map(
                                            (type: any) => (
                                              <button
                                                key={type.value}
                                                type="button"
                                                onClick={() =>
                                                  handleSelectElement(
                                                    row.id,
                                                    col.id,
                                                    type.value as DocumentElementType,
                                                  )
                                                }
                                                className="w-full flex items-center gap-x-3 py-2 px-3 rounded-lg text-[13px] text-gray-800 hover:bg-cyan-100">
                                                {type.name}
                                              </button>
                                            ),
                                          )}
                                          <div className="border-t border-slate-200 py-2 mt-2 mx-3"></div>
                                          <div className="text-slate-400 text-xs font-semibold px-3 py-1">
                                            BASIC INPUT ELEMENTS
                                          </div>
                                          {inputOptions["basic"].map(
                                            (type: any) => (
                                              <button
                                                key={type.value}
                                                type="button"
                                                onClick={() =>
                                                  handleSelectElement(
                                                    row.id,
                                                    col.id,
                                                    type.value as DocumentElementType,
                                                  )
                                                }
                                                className="w-full flex items-center gap-x-3 py-2 px-3 rounded-lg text-[13px] text-gray-800 hover:bg-cyan-100">
                                                {type.name}
                                              </button>
                                            ),
                                          )}

                                          {/* Advanced Input Elements Section */}
                                          <div className="border-t border-slate-200 py-2 mt-2 mx-3"></div>
                                          <div className="text-slate-400 text-xs font-semibold px-3 py-1">
                                            ADVANCED INPUT ELEMENTS
                                          </div>
                                          {inputOptions["advanced"].map(
                                            (type: any) => (
                                              <button
                                                key={type.value}
                                                type="button"
                                                onClick={() =>
                                                  handleSelectElement(
                                                    row.id,
                                                    col.id,
                                                    type.value as DocumentElementType,
                                                  )
                                                }
                                                className="w-full flex items-center gap-x-3 py-1.5 px-3 rounded-lg text-[13px] text-gray-800 hover:bg-cyan-100">
                                                {type.name}
                                              </button>
                                            ),
                                          )}
                                        </div>
                                      </div>
                                    )}
                                </div>
                              );
                            },
                          )}
                        </div>
                      );
                    },
                  )}

                  <div className="flex justify-center">
                    <div className="hs-dropdown [--auto-close:true] relative inline-flex">
                      <button
                        id="hs-dropdown-menu"
                        type="button"
                        onClick={event => toggleDropdown("addRow", event)}
                        className="py-4 px-5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-slate-200 bg-white text-gray-800
                                                       hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700
                                                       dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 relative"
                        ref={addRowButtonRef}>
                        <PlusIcon />
                        Add Row
                      </button>

                      {addRowIsOpen && (
                        <div
                          className="top-full mt-1 absolute hs-dropdown-menu shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)] hs-dropdown-open:opacity-100 w-[110px] transition-[opacity,margin] duration opacity-100 z-10 bg-white rounded-xl
                                                             dark:bg-neutral-900 block"
                          ref={addRowDropdownRef}
                          aria-labelledby="hs-dropdown-menu">
                          <div className="p-1">
                            {[1, 2, 3, 4].map(columns => (
                              <button
                                key={columns}
                                type="button"
                                className="w-full flex gap-x-3 py-1.5 px-3 rounded-lg text-[13px] text-gray-800 hover:bg-cyan-100 disabled:opacity-50
                                                                        disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                                onClick={() => handleAddRow(columns)}>
                                {columns} column{columns > 1 ? "s" : ""}
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              </div>

              {selectedElement ? (
                <div
                  ref={propertiesElementRef}
                  className="w-3/12 p-5 bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700 overflow-auto flex justify-center">
                  <RenderElementProperties
                    element={selectedElement.element}
                    updateElement={updatedElement => {
                      setUpdatedDocumentTemplate((prevTemplate: any) => {
                        const updatedRows = prevTemplate.rows.map(
                          (rowItem: DocumentTemplateRow) => {
                            if (rowItem.id !== selectedElement.rowId)
                              return rowItem;
                            return {
                              ...rowItem,
                              columns: rowItem.columns.map(column => {
                                if (column.id !== selectedElement.colId)
                                  return column;
                                return {
                                  ...column,
                                  element: updatedElement,
                                };
                              }),
                            };
                          },
                        );
                        return {...prevTemplate, rows: updatedRows};
                      });

                      // Actualizar el elemento seleccionado
                      setSelectedElement({
                        ...selectedElement,
                        element: updatedElement,
                      });
                    }}
                  />
                </div>
              ) : (
                <div className="w-3/12 p-5 bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700 overflow-auto flex items-center justify-center">
                  <p className="text-slate-400 text-sm text-center font-medium">
                    Create and select an element on the left to edit its
                    properties here
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
        {activeTab === "Preview" && (
          <div className="flex justify-center">
            <div className=" max-w-[820px] p-5 bg-white border border-gray-200  dark:bg-neutral-800 dark:border-neutral-700 overflow-auto">
              {updatedDocumentTemplate.enableHeader && (
                <div className="flex mb-4">
                  <div className={`flex-1 px-2 relative`}>
                    <div className="w-full h-full min-h-20 border-b border-gray-200 flex items-center justify-start">
                      <div className="text-slate-600 text-lg font-medium font-['Inter']">
                        <img
                          className="pl-3 w-24 h-auto"
                          src={LogoNewHope}
                          alt="Logo"
                        />
                      </div>
                      <div className="text-slate-400 text-md font-light font-['Inter'] leading-normal ml-1 mr-1">
                        |
                      </div>
                      <div className="text-slate-400 text-md font-light font-['Inter'] leading-normal">
                        {updatedDocumentTemplate.name}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {updatedDocumentTemplate.rows?.map((row: DocumentTemplateRow) => {
                if (row._destroy) {
                  return null;
                }
                return (
                  <div key={row.id} className="flex mb-4">
                    {row.columns.map((col: DocumentTemplateColumn) => (
                      <div key={col.id} className={`flex-1 px-2`}>
                        {col.element && col.element._destroy !== true ? (
                          <div className="w-full h-full min-h-20 flex items-end justify-start">
                            <RenderElement
                              element={col.element}
                              updateElement={updatedElement => {}}
                              preview={true}
                            />
                          </div>
                        ) : (
                          <div className="w-full h-full min-h-20 flex items-center justify-center text-slate-400"></div>
                        )}
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
};

export default DocumentTemplateFormPage;
