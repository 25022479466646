import React, { useContext, useEffect, useState } from "react";
import { TitleContext } from "components/TitleContext";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import Table from "components/table/Table";
import Skeleton from "react-loading-skeleton";
import { UserHrDocumentThunks } from "../../features/userHrDocument/userHrDocumentThunks";
import { UserTrainingThunks } from "../../features/userTraining/userTrainingThunks";
import { useDropdownActionMenu } from "../../hooks/useDropdownActionMenu";
import useAttachmentModal from "../../hooks/useAttachmentModal";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import TableCell from "components/table/TableCell";
import WhiteButton from "components/WhiteButton";
import DownloadIcon from "assets/images/icons/DownloadIcon";
import DotsMenu from "components/DotsMenu";
import {SearchType} from "../../types/search";
import {UserHrDocument} from "../../types/userHrDocument";
import {UserTraining} from "../../types/userTraining";
import XCircleIcon from "../../assets/images/icons/XCircleIcon";
import {setMyDashboardTab as setActiveTab } from "../../features/settings/settingsSlice";
import Drawer from "../../components/Drawer";
import UserHrDocumentForm from "../../features/userHrDocument/UserHrDocumentForm";
import UserTrainingForm from "../../features/userTraining/UserTrainingForm";

const MyDashboardPage: React.FC<{}> = () => {
    const dispatch = useDispatch<AppDispatch>();
    const activeTab = useSelector((state: RootState) => state.settings.myDashboardTab);
    const user = useSelector((state: RootState) => state.auth.user);
    const userHrDocuments = useSelector((state: RootState) => state.userHrDocument.userHrDocuments);
    const trainings = useSelector((state: RootState) => state.userTraining.userTrainings);
    const hrDocumentsPagy = useSelector((state: RootState) => state.userHrDocument.pagy);
    const trainingsPagy = useSelector((state: RootState) => state.userTraining.pagy);
    const [pagy, setPagy] = useState<any>(activeTab?.includes("Documents") ? hrDocumentsPagy : trainingsPagy);
    const loading = useSelector(
        (state: RootState) =>
            state.userHrDocument.status === "loading" || state.userTraining.status === "loading"
    );
    const { setTitle } = useContext(TitleContext);
    const { setDotsMenuIsOpen } = useDropdownActionMenu();
    const [selectedAttachmentUrl, setSelectedAttachmentUrl] = useState<string | null>(null);
    const [records, setRecords] = useState<any[]>([]);
    const [searchByOptions, setSearchByOptions] = useState<any[]>([{label: activeTab?.includes("Documents") ? "Document" : "Training", value: 'name', operator: 'like'},]);
    const [recordName, setRecordName] = useState<string>(activeTab?.includes("Documents") ? "Document" : "Training");
    const [isDocumentDrawerOpen, setIsDocumentDrawerOpen] = useState(false);
    const [isTrainingDrawerOpen, setIsTrainingDrawerOpen] = useState(false);
    const [refreshCounter, setRefreshCounter] = useState(0);

    const [visibleColumns, setVisibleColumns] = useState({
        name: true,
        status: true,
        notes: true,
    });
    const [columnOptions, setColumnOptions] = useState<any[]>([
        { label: recordName, value: "name", isVisible: visibleColumns.name },
        { label: 'Status', value: 'status', isVisible: visibleColumns.status},
        { label: 'Notes', value: 'notes', isVisible: visibleColumns.notes},]);

    const openDrawer = () => {
        if (activeTab?.includes("Documents")) {
            setIsDocumentDrawerOpen(true);
            setIsTrainingDrawerOpen(false);
        } else {
            setIsTrainingDrawerOpen(true);
            setIsDocumentDrawerOpen(false);
        }
    }
    const { AttachmentModal, handleNameClick } = useAttachmentModal<any>({
        openDrawer,
        fetchItemThunk: activeTab?.includes("Documents")
            ? UserHrDocumentThunks.show
            : UserTrainingThunks.show,
    });

    interface VisibleColumnsType {
        name: boolean;
        status: boolean;
        notes: boolean;
    }

    const  fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        if (activeTab?.includes('Missing')) {
            search.where.status = {
                operator: '==',
                value: 'missing'
            }
        }
        if (activeTab?.includes('Expired')) {
            search.where.status = {
                operator: '==',
                value: 'expired'
            }
        }
        search.where.user_id = {
            operator: '==',
            value: user?.id as string ,
        };
        if (activeTab?.includes("Documents")) {
            await dispatch(UserHrDocumentThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
        } else {
            await dispatch(UserTrainingThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
        }
    };

    useEffect(() => {
        if (!isDocumentDrawerOpen && !isTrainingDrawerOpen) {
            setRefreshCounter((prev) => prev + 1);
        }
    }, [isDocumentDrawerOpen, isTrainingDrawerOpen]);


    useEffect(() => {
        const labelName = activeTab?.includes("Documents") ? "Document" : "Training";

        setSearchByOptions([{label: labelName, value: 'name', operator: 'like'}]);
        setRecords(activeTab?.includes("Documents") ? userHrDocuments : trainings);
        setPagy(activeTab?.includes("Documents") ? hrDocumentsPagy : trainingsPagy);
        setRecordName(labelName);
        setColumnOptions([
            { label: labelName, value: "name", isVisible: visibleColumns.name },
            { label: 'Status', value: 'status', isVisible: visibleColumns.status},
            { label: 'Notes', value: 'notes', isVisible: visibleColumns.notes},
        ]);
    }, [userHrDocuments, trainings, activeTab]);

    useEffect(() => {
        setTitle(
            <div>
                <div className="text-slate-600 text-lg font-medium">
                    My Dashboard
                </div>
                <div className="text-slate-400 text-sm font-light">
                    {user?.firstName} {user?.lastName}
                </div>
            </div>
        );
    }, [user]);


    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };
    const handleEdit = async (id: string) => {
        await dispatch(
            activeTab?.includes("Documents")
                ? UserHrDocumentThunks.show(id)
                : UserTrainingThunks.show(id)
        );
        openDrawer();
    };

    const handleDelete = async (id: string) => {
        if (activeTab?.includes("Documents")) {
            const newHrDocument = { id, attachment: null, deleteAttachment: true };
            await dispatch(UserHrDocumentThunks.update(newHrDocument));
        } else {
            const newTrainings = new UserTraining({id, attachment: null, deleteAttachment: true});
            await dispatch(UserTrainingThunks.update(newTrainings));
        }
        setDotsMenuIsOpen(null);
    };

    const handleDownload = (attachmentUrl: string) => {
        if (attachmentUrl) {
            window.open(attachmentUrl, "_blank");
        }
    };
    const handleSelectDownload = () => {
        if (selectedAttachmentUrl) {
            window.open(selectedAttachmentUrl, '_blank');
        }
    };

    return (
      <>
        <Table
          tabs={[
            "Missing Documents",
            "Expired Documents",
            "Missing Trainings",
            "Expired Trainings",
          ]}
          refreshData={refreshCounter}
          activeTab={activeTab}
          fetchData={fetchData}
          setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
          pagy={pagy}
          recordsName={
            `${recordName}s`
          }
          loading={loading}
          columns={true}
          columnOptions={columnOptions}
          searchByOptions={searchByOptions}
          onColumnToggle={handleColumnToggle}
          blueButton={
            <WhiteButton
              onClick={handleSelectDownload}
              label={""}
              icon={<DownloadIcon />}
            />
          }>
          <thead>
            <tr>
              <th scope="col" className="px-3 pt-3.5 flex justify-center">
                <input
                  type="checkbox"
                  className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500
                            dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
                />
              </th>
              {visibleColumns.name && (
                <TableHeader
                  label={
                    recordName
                  }
                  sortBy="name"
                />
              )}
              {visibleColumns.status && (
                <TableHeader label="Status" sortBy="status" />
              )}
              {visibleColumns.notes && (
                <TableHeader label="Notes" sortBy="notes" />
              )}
              <TableHeader label="" />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {records?.length !== 0 &&
                records?.map((record: UserHrDocument | UserTraining) => {
                const userOptions = [
                  {
                    label: "Edit",
                    onClick: () => handleEdit(record.id),
                    hoverClass: "hover:bg-cyan-100",
                  },
                  ...(record.status !== "Missing"
                    ? [
                        {
                          label: "Download",
                          onClick: () =>
                            handleDownload(
                              record.attachment?.url || "",
                            ),
                          hoverClass: "hover:bg-cyan-100",
                        },
                        {
                          label: "Remove attached document",
                          onClick: () => handleDelete(record.id),
                          hoverClass: "hover:bg-red-100 hover:text-red-800",
                        },
                      ]
                    : []),

                  ...(record.status === "Missing"
                    ? [
                        {
                          label: "Upload",
                          onClick: () => handleEdit(record.id),
                          hoverClass: "hover:bg-cyan-100",
                        },
                      ]
                    : []),
                ];
                if (loading) {
                  return (
                    <TableRow key={record.id}>
                      <td colSpan={6}>
                        <Skeleton count={1} height={40} />
                      </td>
                    </TableRow>
                  );
                } else {
                  return (
                    <TableRow key={record.id}>
                      <td className="px-3 py-2 flex justify-center">
                        <input
                          type="checkbox"
                          className="border border-gray-300 rounded"
                        />
                      </td>
                      {visibleColumns.name && (
                        <TableCell>
                          <span
                            className={`text-sm font-medium ${
                              record.status !== "Missing"
                                ? "underline text-cyan-600 cursor-pointer"
                                : ""
                            }`}
                            onClick={
                              record.status !== "Missing"
                                ? () => handleNameClick(record)
                                : undefined
                            }>
                            {record.name}
                          </span>
                        </TableCell>
                      )}
                      {visibleColumns.status && (
                        <TableCell>
                          <span
                            className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-32
                                    ${record.status === "Missing" ? "bg-red-100 text-red-800" : ""}
                                    ${record.status === "Expired" ? "bg-red-100 text-red-800" : ""}
                                    `}>
                            {record.status === "Missing" && (
                              <XCircleIcon className="mr-2" />
                            )}
                            {record.status === "Expired" && (
                              <XCircleIcon className="mr-2" />
                            )}

                            <div className="pl-1">{record.status}</div>
                          </span>
                        </TableCell>
                      )}
                      {visibleColumns.notes && (
                        <TableCell>{record.notes}</TableCell>
                      )}
                      <TableCell>
                        <DotsMenu options={userOptions} />
                      </TableCell>
                    </TableRow>
                  );
                }
              })}

          </tbody>
        </Table>
        <AttachmentModal />
          <Drawer isOpen={isDocumentDrawerOpen} onClose={() => setIsDocumentDrawerOpen(false)}>
              <UserHrDocumentForm closeDrawer={() => setIsDocumentDrawerOpen(false)} isDrawerOpen={isDocumentDrawerOpen} />
          </Drawer>
          <Drawer isOpen={isTrainingDrawerOpen} onClose={() => setIsTrainingDrawerOpen(false)}>
              <UserTrainingForm closeDrawer={() => setIsTrainingDrawerOpen(false)} isDrawerOpen={isTrainingDrawerOpen} />
          </Drawer>
      </>
    );
};

export default MyDashboardPage;