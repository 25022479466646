import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import React, {useEffect, useState} from "react";
import SubmitButton from "components/SubmitButton";
import DescriptionInput from "components/inputs/DescriptionInput";
import {UserHrDocumentThunks} from "features/userHrDocument/userHrDocumentThunks";
import {UserHrDocument} from "types/userHrDocument";
import FileInput from "components/inputs/FileInput";
import XIcon from "assets/images/icons/XIcon";
import FileDetailBox from "components/FileDetailBox";
import DateInput from "../../components/inputs/DateInput";
import BlueButton from "../../components/BlueButton";
import {UploadRequestService} from "../../services/uploadRequestService";
import {UploadRequest} from "../../types/uploadRequest";
import {showToast} from "../../components/ToastContainer";

interface UserHrDocumentFormProps {
  id?: string;
  closeDrawer: () => void;
  isDrawerOpen: boolean;
}

const UserHrDocumentForm: React.FC<UserHrDocumentFormProps> = ({
  id,
  closeDrawer,
  isDrawerOpen,
}) => {
  const userHrDocument = useSelector(
    (state: RootState) => state.userHrDocument.userHrDocument,
  );
  const dispatch = useDispatch<AppDispatch>();
  const [updatedUserHrDocument, setUpdatedUserHrDocument] =
    useState(userHrDocument);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFileChange = (files: FileList | null) => {
    if (files && files.length > 0) {
      const attachment = files[0];
      setUploadedFile(attachment);
      setUpdatedUserHrDocument((prevState: UserHrDocument) => ({
        ...prevState,
        attachment: attachment,
      }));
      setIsLoadingFile(true);
    }
  };

  useEffect(() => {
    if (!isLoadingFile) return;

    const loadingDuration = 2000;
    const timer = setTimeout(() => {
      setIsLoadingFile(false);
    }, loadingDuration);

    return () => clearTimeout(timer);
  }, [isLoadingFile]);

  useEffect(() => {
    const isFormValid =
      (uploadedFile !== null || userHrDocument?.attachmentUrl) &&
      !isLoadingFile;

    setIsSaveDisabled(!isFormValid);
  }, [uploadedFile, updatedUserHrDocument, isLoadingFile]);

  useEffect(() => {
    setUpdatedUserHrDocument(userHrDocument);
    setIsSaveDisabled(!userHrDocument?.name);
  }, [userHrDocument]);

  useEffect(() => {
    if (!isDrawerOpen) handleClose();
  }, [isDrawerOpen]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const {id, value} = e.target;
    setUpdatedUserHrDocument({...updatedUserHrDocument, [id]: value});
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const userHrDocumentToSubmit = new UserHrDocument(updatedUserHrDocument);
    setIsSubmitting(true);
    setIsSaveDisabled(true);
    if (userHrDocument?.id) {
      await dispatch(UserHrDocumentThunks.update(userHrDocumentToSubmit)).then(
        () => closeDrawer(),
      );
    } else {
      await dispatch(UserHrDocumentThunks.create(userHrDocumentToSubmit)).then(
        () => closeDrawer(),
      );
    }
  };

  const handleClose = () => {
    setUpdatedUserHrDocument({
      id: "",
      notes: "",
      expiresAt: "",
      issuedAt: "",
    });

    setUploadedFile(null);
    setIsSaveDisabled(true);
    setIsSubmitting(false);
  };

  const handleDelete = () => {
    setUploadedFile(null);
    setUpdatedUserHrDocument((prevState: UserHrDocument) => ({
      ...prevState,
      attachment: null,
      deleteAttachment: true,
      attachmentUrl: null,
    }));
  };
  const handleRequestEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    UploadRequestService.create({
      ownerType: "UserHrDocument",
      ownerId: userHrDocument?.id,
      ownerField: "attachment",
    } as UploadRequest).then(() => {
      showToast("Email sent successfully", "success");
    });
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 flex flex-col">
        <div className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
          <div className="flex flex-col justify-start items-start gap-1">
            <div className="text-lg font-semibold tracking-normal text-cyan-800">
              {`Upload ${userHrDocument?.name}`}
            </div>

            <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
              {`Use the form below to upload the employee’s ${userHrDocument?.name} and complete this requirement.`}
            </div>
          </div>
          <button
            onClick={closeDrawer}
            className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
            <XIcon />
          </button>
        </div>
        <div className="h-full px-6 pb-6 ">
          <div className="mt-5">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-6 items-center mt-7">
                <div className="w-full">
                  <FileInput
                    onFileChange={handleFileChange}
                    label="Drop your files here or"
                    labelBlue="browse"
                    subtitle="Maximum size: 50MB"
                    disabled={isLoadingFile}
                  />
                </div>
                {uploadedFile && (
                  <div className="w-full">
                    <FileDetailBox
                      uploadedFile={uploadedFile}
                      handleDelete={handleDelete}
                      isLoading={isLoadingFile}
                      loadingTime={2000}
                    />
                  </div>
                )}
                {userHrDocument?.attachment?.url &&
                  uploadedFile === null &&
                  updatedUserHrDocument.attachment?.url !== null && (
                    <div className="w-full">
                      <FileDetailBox
                        uploadedFile={userHrDocument?.attachment}
                        handleDelete={handleDelete}
                        fileCurrent={true}
                      />
                    </div>
                  )}

                {userHrDocument?.previousAttachments &&
                  userHrDocument?.previousAttachments.map(
                    (file: any, index: number) => (
                      <div className="w-full">
                        <FileDetailBox
                          uploadedFile={file}
                          hideDelete={true}
                          handleDelete={handleDelete}
                          fileCurrent={false}
                        />
                      </div>
                    ),
                  )}
                <div className="w-full">
                  <DescriptionInput
                    id="notes"
                    type="text"
                    label="Notes"
                    value={updatedUserHrDocument?.notes}
                    placeholder="Notes"
                    onChange={handleChange}
                    optional="optional"
                  />
                </div>
                <div className="w-full ">
                  <button
                    onClick={e => {
                      handleRequestEmail(e);
                    }}
                    className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                    Request via email
                  </button>
                </div>
                {userHrDocument?.hrDocument?.requireIssueDate && (
                  <div className="w-full">
                    <DateInput
                      id="issuedAt"
                      label="Issue date"
                      type="date"
                      value={updatedUserHrDocument?.issuedAt}
                      onChange={handleChange}
                      placeholder="MM/DD/YYYY"
                      allowPastDates={true}
                    />
                  </div>
                )}
                {userHrDocument?.hrDocument?.requireExpirationDate && (
                  <div className="w-full">
                    <DateInput
                      id="expiresAt"
                      label="Expiration date"
                      type="date"
                      value={updatedUserHrDocument?.expiresAt}
                      onChange={handleChange}
                      placeholder="MM/DD/YYYY"
                    />
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
        <div className="border-t border-slate-200 w-full">
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <SubmitButton
              onClick={handleSubmit}
              label={`${isSubmitting ? "Uploading" : "Upload"}`}
              disabled={isSaveDisabled || isSubmitting}
            />
          </div>
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <button
              type="submit"
              onClick={closeDrawer}
              className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
              Never mind
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default UserHrDocumentForm;
