import React from 'react';
import { SignatureInputElement } from 'types/documentTemplateElement';
import RadioInputHorizontal from "../../inputs/RadioInputHorizontal";
import TextInput from "../../inputs/TextInput";

interface SignatureInputPropertiesProps {
  element: SignatureInputElement;
  updateElement: (updatedElement: SignatureInputElement) => void;
}

const SignatureInputProperties: React.FC<SignatureInputPropertiesProps> = ({ element, updateElement }) => {
  const handleChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      ) => {
          const {id, value, type} = e.target;
          updateElement({
              ...element,
              properties: {
                  ...element.properties,
                  [id]: value
              },
          });
      };

  return (
      <div className="space-y-5 w-full">

          <RadioInputHorizontal
              label="Required"
              options={[
                  {id: 'required-yes', label: 'Yes', value: true},
                  {id: 'required-no', label: 'No', value: false},
              ]}
              selectedValue={element.properties.required}
              onChange={(value) => updateElement({...element, properties: {...element.properties, required: value}})}
          />
          <RadioInputHorizontal
              label="Admin Only"
              options={[
                  {id: 'adminOnly-yes', label: 'Yes', value: true},
                  {id: 'adminOnly-no', label: 'No', value: false},
              ]}
              selectedValue={element.properties.adminOnly}
              onChange={(value) => updateElement({...element, properties: {...element.properties, adminOnly: value}})}
          />
      </div>
  );
};

export default SignatureInputProperties;
