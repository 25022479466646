// DropdownProperties.tsx
import React, {useState} from 'react';
import {DropdownElement} from 'types/documentTemplateElement';
import RadioInputHorizontal from "../../inputs/RadioInputHorizontal";
import CustomSelect from "../../inputs/CustomSelect";
import TextInput from "../../inputs/TextInput";
import XIcon from "../../../assets/images/icons/XIcon";
import LinksIcon from "../../../assets/images/icons/LinksIcon";
import PlusIcon from "../../../assets/images/icons/PlusIcon";

interface DropdownPropertiesProps {
    element: DropdownElement;
    updateElement: (updatedElement: DropdownElement) => void;
}

const DropdownProperties: React.FC<DropdownPropertiesProps> = ({
                                                                     element,
                                                                     updateElement,
                                                                 }) => {
    const [inputValue, setInputValue] = useState('');
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const {id, value, type} = e.target;
        updateElement({
            ...element,
            properties: {
                ...element.properties,
                [id]: value
            },
        });
    };

    const handleAddOption = () => {
        updateElement({
            ...element,
            properties: {
                ...element.properties,
                options: [...element.properties.options, inputValue],
            }
        });
        setInputValue('');
    }

    const handleRemoveOption = (index: number) => {
        updateElement({
            ...element,
            properties: {
                ...element.properties,
                options: element.properties.options.filter((_, i) => i !== index),
            },
        });
    }

    return (
        <div className="space-y-5 w-full">
            <RadioInputHorizontal
                label="Label"
                options={[
                    {id: 'show-label-yes', label: 'Yes', value: true},
                    {id: 'show-label-no', label: 'No', value: false},
                ]}
                selectedValue={element.properties.showLabel}
                onChange={(value) => updateElement({...element, properties: {...element.properties, showLabel: value}})}
            />
            {element.properties.showLabel && (
                <TextInput
                    id="label"
                    type="input"
                    label="Label Text"
                    value={element.properties.label}
                    onChange={handleChange}
                />
            )}
            <RadioInputHorizontal
                label="Required"
                options={[
                    {id: 'required-yes', label: 'Yes', value: true},
                    {id: 'required-no', label: 'No', value: false},
                ]}
                selectedValue={element.properties.required}
                onChange={(value) => updateElement({...element, properties: {...element.properties, required: value}})}
            />
            <RadioInputHorizontal
                label="Admin Only"
                options={[
                    {id: 'adminOnly-yes', label: 'Yes', value: true},
                    {id: 'adminOnly-no', label: 'No', value: false},
                ]}
                selectedValue={element.properties.adminOnly}
                onChange={(value) => updateElement({...element, properties: {...element.properties, adminOnly: value}})}
            />

            <RadioInputHorizontal
                label="Multi"
                options={[
                    {id: 'multi-yes', label: 'Yes', value: true},
                    {id: 'multi-no', label: 'No', value: false},
                ]}
                selectedValue={element.properties.isMulti}
                onChange={(value) => updateElement({...element, properties: {...element.properties, isMulti: value}})}
            />

            <div className="space-y-5 w-full">
                <div className="flex items-center space-x-2 space-y-2">
                    <div className="flex-1">
                        <TextInput
                            id="trainingLinks"
                            type="text"
                            label="Options"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                    </div>
                    <div className="pt-5 ">
                        <button
                            onClick={handleAddOption}
                            className="py-3 px-3 h-full inline-flex items-center text-xs rounded-lg shadow
                                                               disabled:opacity-50 focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700
                                                               dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 relative
                                                               border border-slate-200  text-slate-800 hover:bg-cyan-100
                                                               disabled:pointer-events-none disabled:bg-zinc-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                            <PlusIcon/>
                        </button>
                    </div>
                </div>
                {element.properties.options.map((option, index) => (
                    <div
                        className="h-10 w-full p-3 bg-slate-50 rounded-lg border border-gray-200 justify-start items-center gap-5 inline-flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                            <div className="self-stretch pr-9 justify-start items-start gap-2.5 inline-flex">
                                <div
                                    className="grow shrink basis-0 text-gray-600 text-base font-normal font-['Inter'] leading-normal tracking-tight">

                                    {option}
                                </div>
                            </div>
                        </div>
                        <button
                            className="text-gray-500 hover:text-gray-700 focus:outline-none "
                            onClick={() => handleRemoveOption(index)}>
                            <XIcon/>
                        </button>
                    </div>
                ))}
            </div>

        </div>
    );
};

export default DropdownProperties;
